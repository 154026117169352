import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { StaticSvg } from './svg-icon-static.models';

@Component({
  selector: 'sfu-svg-icon-static',
  standalone: true,
  imports: [NgClass],
  templateUrl: './svg-icon-static.component.html',
  styleUrl: './svg-icon-static.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconStaticComponent {
  protected readonly StaticSvg = StaticSvg;

  @Input() src: StaticSvg = StaticSvg.FEATHER;
  @Input() svgClass: string | undefined;
}

import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {
  getAnalytics,
  isSupported,
  provideAnalytics,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    provideClientHydration(),
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAnalytics(() => {
        if (isSupported()) {
          const app = getApp();
          // On prod, because of gdpr, we disable automatic data collection
          app.automaticDataCollectionEnabled = !environment.production;
          return getAnalytics(app);
        } else {
          throw new Error(
            'Firebase Analytics is not supported in this environment',
          );
        }
      }),
    ),
  ],
};

<sfl-header></sfl-header>
<div class="sidenav-main-wrapper">
  <div class="content">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
@defer {
  <sfl-footer></sfl-footer>
}

<div class="header-row">
  <div class="logo-row row">
    <a class="logo-wrapper" routerLink="/">
      <sfu-svg-icon-static class="logo-svg" [svgClass]="'very-big'" />
      <span class="logo-text font-big landing">schriftfuehrer.at</span>
    </a>
  </div>

  <a class="button-primary" [href]="environment.domains.app">
    Kostenlos testen
  </a>
</div>
<hr />

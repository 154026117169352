import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Analytics } from '@angular/fire/analytics';
import { isPlatformBrowser } from '@angular/common';

declare let Cookiebot: any;

@Injectable({
  providedIn: 'root',
})
/**
 * Cookiebot events: https://www.cookiebot.com/en/developer/
 */
export class AnalyticsService {
  private analytics: Analytics = inject(Analytics);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  init(): void {
    if (!this.isBrowser) {
      return;
    }

    console.log(this.analytics.app);

    window.addEventListener('CookiebotOnAccept', (e) => {
      // If marketing cookies are accepted, enable automatic data collection
      if (Cookiebot.consent.marketing) {
        this.analytics.app.automaticDataCollectionEnabled = true;
      }
      console.log(this.analytics.app);
    });

    window.addEventListener('CookiebotOnDecline', (e) => {
      // If marketing cookies are declined, disable automatic data collection
      if (Cookiebot.consent.marketing) {
        this.analytics.app.automaticDataCollectionEnabled = false;
      }
      console.log(this.analytics.app);
    });
  }
}

@switch (src) {
  @case (StaticSvg.FEATHER) {
    <svg
      [ngClass]="svgClass"
      id="svg132"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs136" />
      <g id="g138">
        <g id="g1532" transform="translate(-0.25,0.5)">
          <g id="g1527">
            <g
              id="g1544"
              transform="matrix(0.95465422,0,0,0.95465422,45.755509,4.4639002)"
            >
              <path
                style="
                  fill: var(--pink);
                  stroke-width: 0.0893493;
                  fill-opacity: 1;
                "
                d="m -45.011223,19.880525 c 0.198752,-0.732812 0.38945,-1.386528 0.658501,-2.266012 0.421467,-1.377711 0.536787,-1.864089 0.839496,-1.825657 0.369762,0.04695 0.863918,0.341293 0.990323,0.634253 0.07065,0.163749 -0.17311,0.372343 -0.743446,1.10201 -0.465178,0.595132 -0.734939,0.949031 -1.035223,1.376717 -0.767704,1.093419 -0.734398,1.118399 -0.709651,0.978689 z"
                id="path353"
              />
              <path
                style="
                  fill: var(--pink);
                  stroke-width: 0.0128164;
                  fill-opacity: 1;
                "
                d="m -42.147967,6.1415792 c 0.160948,-0.241102 0.388959,-0.565106 0.494663,-0.702773 0.05051,-0.06583 0.05051,-0.06583 0.0755,0.09963 0.147365,0.977412 0.563624,2.0777 1.055123,2.788974 0.110097,0.159328 0.11981,0.164544 0.09605,0.05161 -0.447487,-2.127531 -0.05929,-4.4534879 0.903385,-5.4127429 1.21184,-1.207539 2.342166,-2.155166 3.820093,-3.202637 2.292024,-1.6244573 5.194537,-3.1156314 8.164256,-4.1944049 0.972667,-0.3533296 2.423043,-0.8220971 2.36383,-0.7640013 -0.870028,0.8536129 -2.84211,3.3922609 -4.177634,5.3778402 -0.376986,0.560474 -0.325107,0.498811 -0.491462,0.584246 -0.934807,0.480089 -2.146516,1.569093 -3.269668,2.938559 -0.103156,0.125787 -0.09459,0.124319 0.0724,-0.01237 0.355121,-0.290678 0.869819,-0.672346 1.30666,-0.968942 0.350579,-0.238024 1.091981,-0.683146 1.167407,-0.700885 0.02296,-0.0054 -0.422774,0.688912 -0.875924,1.36451 -0.415317,0.619158 -1.186213,1.8540289 -1.421848,2.2776749 -0.799923,1.437907 -1.599299,3.043769 -2.624428,5.2721968 -0.328556,0.714223 -0.59067,1.172394 -0.87564,1.530617 -1.576199,1.981385 -4.10638,3.262853 -5.720421,2.897238 -0.160153,-0.03627 -0.161689,-0.03764 -0.126407,-0.111638 0.01602,-0.03359 0.115817,-0.250819 0.221752,-0.482685 2.146662,-4.698245 5.331676,-9.7354808 8.26068,-13.0646217 0.124243,-0.141215 0.172488,-0.209747 0.07682,-0.109162 -0.02615,0.02753 -0.152115,0.158227 -0.279892,0.290478 -2.623351,2.715106 -5.493892,6.7738349 -8.014458,11.3318417 -0.31407,0.567934 -0.809032,1.499519 -0.903206,1.699945 -0.03567,0.07587 -0.04744,0.06827 -0.229474,-0.148317 -0.661165,-0.786508 -0.953396,-2.188322 -0.80536,-3.863259 0.157767,-1.7850248 0.651691,-3.1403468 1.737236,-4.7669528 z"
                id="path1489"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  }
  @case (StaticSvg.GOOGLE) {
    <svg
      class="google-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="2443"
      height="2500"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 256 262"
      id="google"
    >
      <path
        fill="#4285F4"
        d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
      ></path>
      <path
        fill="#34A853"
        d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
      ></path>
      <path
        fill="#FBBC05"
        d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
      ></path>
      <path
        fill="#EB4335"
        d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
      ></path>
    </svg>
  }
}

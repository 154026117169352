import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./pages/legal/privacy/privacy.component').then(
        (m) => m.PrivacyComponent,
      ),
  },
  {
    path: 'imprint',
    loadComponent: () =>
      import('./pages/legal/imprint/imprint.component').then(
        (m) => m.ImprintComponent,
      ),
  },
  {
    path: 'agb',
    loadComponent: () =>
      import('./pages/legal/agb/agb.component').then((m) => m.AgbComponent),
  },
  { path: '**', redirectTo: '' },
];

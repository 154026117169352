import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgClass, NgStyle } from '@angular/common';
import { SvgIconStaticComponent } from '../../../../../../../shared/components/svg-icon-static/svg-icon-static.component';

@Component({
  selector: 'sfl-header',
  standalone: true,
  imports: [RouterLink, NgStyle, NgClass, SvgIconStaticComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  protected readonly environment = environment;
}
